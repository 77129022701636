
.mobile-container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  .icon-chatgpt {
    width: .41rem;
    height: .41rem;
    position: fixed;
    bottom: .50rem;
    right: .20rem;
    z-index: 999999999;
  }
  .loading-super {
    width: 120%;
    height: 120%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2000;
    background-color: #000000;
    .video-loading {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .nav-super {
    background-color: #000000;
    opacity: 0.95;
    width: 100%;
    height: .57rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 .18rem 0 .24rem;
    position: fixed;
    top: 0;
    z-index: 1002;
    .nav-logo {
      width: 1.06rem;
      height: .32rem;
    }
    .nav-button {
      width: .26rem;
      height: .19rem;
      &.close {
        width: .19rem;
      }
    }
  }
  .bottom-super {
    background-color: #000000;
    opacity: 0.95;
    width: 100%;
    height: .60rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 .22rem 0 .25rem;
    font-size: .14rem;
    font-weight: 400;
    color: #FFFFFF;
    position: fixed;
    bottom: 0;
    z-index: 1000;
    .contact-button {
      width: .82rem;
      height: .35rem;
      line-height: .35rem;
      background-color: #539FFE;
      text-align: center;
      border-radius: .05rem;
    }
  }
  .bottom-blank {
    width: 100%;
    height: .60rem;
  }
  .swiper-super {
    width: 100%;
    height: 6.67rem;
    position: relative;
    z-index: 9;
    .block-devide-1 {
      position: absolute;
      bottom: -.20rem;
      width: 100%;
    }
    .next-icon {
      position: absolute;
      bottom: 0.85rem;
      left: 50%;
      transform: translateX(-50%);
      width: .26rem;
      height: .36rem;
      animation: heart .6s ease-in-out 1.3s infinite alternate;
    }
    .swiper-content {
      width: 100%;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      user-select: none;
      position: relative;
      .swiper-img {
        width: 100%;
      }
      .swiper-title {
        position: absolute;
        top: 4.26rem;
        left: 50%;
        transform: translateX(-50%);
        height: .74rem;
      }
      .play-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: .53rem;
        height: .61rem;
      }
    }
    .button-box {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: .54rem;
      height: .55rem;
      display: flex;
      align-items: center;
      justify-content: center;
      &.left {
        left: 0;
      }
      &.right {
        right: 0;
      }
      .button-arrow {
        width: .08rem;
        height: .15rem;
        cursor: pointer;
        &.prev {
          transform: rotate(180deg);
        }
      }
    }
    .adm-swiper-indicator {
      bottom: .75rem;
      right: .24rem;
      left: initial;
      transform: translateX(0);
      .adm-page-indicator-dot {
        width: .07rem;
        height: .07rem;
        background-color: #FFFFFF;
        border-radius: .04rem;
        opacity: .5;
        &.adm-page-indicator-dot-active {
          opacity: 1;
        }
      }
    }
  }
  .mobile-block {
    width: 100%;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: -.35rem;
    .block-devide-1 {
      position: absolute;
      bottom: -.20rem;
      width: 100%;
    }
    .block-devide-2 {
      position: absolute;
      bottom: -.03rem;
      width: 100%;
    }
    &.assets {
      height: 5.89rem;
      z-index: 6;
    }
    &.digital {
      height: 8.10rem;
      z-index: 5;
    }
    &.infinite {
      height: 8.21rem;
      z-index: 4;
    }
    &.terminal {
      height: 4.82rem;
      z-index: 3;
    }
    &.nft {
      height: 8.80rem;
      z-index: 2;
    }
    &.product {
      height: 6.68rem;
      z-index: 1;
    }
    .block-title {
      width: 3.25rem;
      height: .34rem;
      padding-top: .70rem;
    }
    .block-subtitle {
      width: 100%;
      padding: .14rem 0 0 .30rem;
      font-size: .14rem;
      font-weight: 300;
      color: #FFFFFF;
      text-shadow: 0 0 0.2em rgba(132, 255, 255, .45), -0 -0 0.2em rgba(132, 255, 255, .45);
      line-height: .20rem;
    }
    .assets-car {
      width: 2.96rem;
      height: 2.74rem;
      position: absolute;
      right: 0;
      bottom: .23rem;
    }
    .assets-woman {
      width: 1.16rem;
      height: 3.69rem;
      position: absolute;
      left: 1.05rem;
      bottom: .19rem;
    }
    .assets-box-1 {
      width: 2.14rem;
      height: .91rem;
      position: absolute;
      bottom: 1.34rem;
      left: 0;
      visibility: hidden;
      transition: visibility 0s .5s;
      &.twinkle {
        animation: twinkling 1s ease-in-out .5s;
        visibility: visible;
      }
    }
    .assets-box-2 {
      width: .96rem;
      height: .61rem;
      position: absolute;
      bottom: .91rem;
      left: 1.14rem;
      visibility: hidden;
      transition: visibility 0s 1s;
      &.twinkle {
        animation: twinkling 1s ease-in-out 1s;
        visibility: visible;
      }
    }
    .assets-box-3 {
      width: 1.01rem;
      height: 1.08rem;
      position: absolute;
      bottom: 1.45rem;
      right: .57rem;
      visibility: hidden;
      transition: visibility 0s 1.5s;
      &.twinkle {
        animation: twinkling 1s ease-in-out 1.5s;
        visibility: visible;
      }
    }
    .assets-img-1 {
      width: 1.16rem;
      height: .77rem;
      position: absolute;
      bottom: 1.98rem;
      left: .06rem;
      visibility: hidden;
      transition: visibility 0s 0s;
      &.appear {
        animation: appearLeft .5s linear;
        visibility: visible;
      }
    }
    .assets-img-2 {
      width: 1.06rem;
      height: .68rem;
      position: absolute;
      bottom: .62rem;
      left: 2.00rem;
      visibility: hidden;
      transition: visibility 0s .5s;
      &.appear {
        animation: appearLeft .5s linear .5s;
        visibility: visible;
      }
    }
    .assets-img-3 {
      width: 1.02rem;
      height: .99rem;
      position: absolute;
      bottom: 2.45rem;
      right: .13rem;
      visibility: hidden;
      transition: visibility 0s 1s;
      &.appear {
        animation: appearRight .5s linear 1s;
        visibility: visible;
      }
    }
    .digital-list {
      width: 2.96rem;
      padding-top: .17rem;
      padding-bottom: .25rem;
      .digital-item {
        padding-bottom: .12rem;
        visibility: hidden;
        &:last-child {
          padding-bottom: 0;
        }&.appear {
          visibility: visible;
        }
        &.item-1 {
          transition: visibility 0s 0s;
          &.appear {
            animation: appearLeft .5s linear 0s;
          }
        }
        &.item-2 {
          transition: visibility 0s .5s;
          &.appear {
            animation: appearRight2 .5s linear .5s;
          }
        }
        &.item-3 {
          transition: visibility 0s 1s;
          &.appear {
            animation: appearLeft .5s linear 1s;
          }
        }
        &.item-4 {
          transition: visibility 0s 1.5s;
          &.appear {
            animation: appearRight2 .5s linear 1.5s;
          }
        }
        .image {
          width: 2.96rem;
          height: 1.07rem;
          padding-bottom: .09rem;
        }
        .title-super {
          display: flex;
          align-items: center;
          .icon {
            width: .19rem;
            height: .19rem;
            padding-right: .09rem;
          }
          .step {
            width: .33rem;
            height: .14rem;
            padding-right: .14rem;
          }
          .title {
            display: flex;
            align-items: flex-end;
            height: .18rem;
            font-size: .14rem;
            font-weight: 400;
            color: #FFFFFF;
            text-shadow: 0 0 0.2em rgba(132, 255, 255, .45), -0 -0 0.2em rgba(132, 255, 255, .45);
          }
        }
      }
    }
    .digital-button {
      width: 2.23rem;
      height: .30rem;
      line-height: .30rem;
      border: 1px solid #FFFFFF;
      text-align: center;
      font-size: .12rem;
      font-weight: 400;
      color: #FFFFFF;
    }
    .infinite-list {
      width: 3.24rem;
      padding-top: .23rem;
      .infinite-item {
        width: 3.24rem;
        height: 1.45rem;
        background-size: cover;
        position: relative;
        margin-bottom: .20rem;
        visibility: hidden;
        &:last-child {
          margin-bottom: 0;
        }
        &.appear {
          visibility: visible;
        }
        &.item-1 {
          transition: visibility 0s 0s;
          &.appear {
            animation: appearLeft .5s linear 0s;
          }
        }
        &.item-2 {
          transition: visibility 0s .5s;
          &.appear {
            animation: appearRight2 .5s linear .5s;
          }
        }
        &.item-3 {
          transition: visibility 0s 1s;
          &.appear {
            animation: appearLeft .5s linear 1s;
          }
        }
        &.item-4 {
          transition: visibility 0s 1.5s;
          &.appear {
            animation: appearRight2 .5s linear 1.5s;
          }
        }
        .item-intro {
          width: 100%;
          height: .45rem;
          background-color: rgba(0, 0, 0, .63);
          position: absolute;
          left: 0;
          bottom: 0;
          font-size: .13rem;
          font-weight: 400;
          color: #FFFFFF;
          line-height: .45rem;
          padding-left: .27rem;
        }
      }
    }
    .terminal-slide-super {
      width: 100%;
      margin-top: .46rem;
      position: relative;
      touch-action: pan-y;
      .terminal-super {
        position: absolute;
        background-size: cover;
        color: #FFFFFF;
        text-align: center;
        transition: all .5s ease-out;
        text-shadow: 0 0 0.2em rgba(132, 255, 255, .45), -0 -0 0.2em rgba(132, 255, 255, .45);
        &.terminal-mobile {
          width: 2.53rem;
          height: 1.49rem;
          .title {
            top: -.08rem;
          }
          .intro {
            bottom: -.24rem;
          }
        }
        &.terminal-pc {
          width: 2.06rem;
          height: 1.78rem;
          .title {
            top: -.20rem;
          }
          .intro {
            bottom: -.24rem;
          }
        }
        &.terminal-vr {
          width: 2.24rem;
          height: 1.62rem;
          .title {
            top: -.20rem;
          }
          .intro {
            bottom: -.40rem;
          }
        }
        &.position-0 {
          top: .40rem;
          left: 50%;
          transform: translateX(-50%);
          z-index: 2;
          .title, .intro {
            display: block;
          }
        }
        &.position-1 {
          top: -.50rem;
          left: 80%;
          transform: translateX(-50%) scale(.5, .5);
          z-index: 1;
          opacity: .5;
        }
        &.position-2 {
          top: -.50rem;
          left: 20%;
          transform: translateX(-50%) scale(.5, .5);
          z-index: 1;
          opacity: .5;
        }
        .title {
          width: 100%;
          font-size: .12rem;
          font-weight: 400;
          position: absolute;
          display: none;
        }
        .intro {
          width: 100%;
          font-size: .11rem;
          font-weight: 300;
          position: absolute;
          display: none;
        }
      }
    }
    .button-group {
      width: 100%;
      position: absolute;
      top: 55%;
      left: 0;
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      .button-box {
        width: .54rem;
        height: .55rem;
        display: flex;
        align-items: center;
        justify-content: center;
        .button-arrow {
          width: .08rem;
          height: .15rem;
          cursor: pointer;
          &.prev {
            transform: rotate(180deg);
          }
        }
      }
    }
    .dots-group {
      width: .30rem;
      position: absolute;
      bottom: .50rem;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      justify-content: space-between;
      .dot {
        width: .07rem;
        height: .07rem;
        background-color: #FFFFFF;
        border-radius: .04rem;
        opacity: .5;
        &.active {
          opacity: 1;
        }
      }
    }
  }
  .product-tab-title {
    width: 3.24rem;
    box-sizing: border-box;
    border-bottom: 1px solid #6FA3CB;
    display: flex;
    font-size: .15rem;
    font-weight: 400;
    color: #FFFFFF;
    padding: .43rem 0 .06rem .22rem;
    .title-item {
      margin-right: .30rem;
      opacity: 0.6;
      position: relative;
      &.active {
        opacity: 1;
        &::after {
          content: '';
          width: calc(100% + .12rem);
          height: .03rem;
          background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/product-active-bottom.png);
          background-size: 100% 100%;
          position: absolute;
          left: -.06rem;
          bottom: -.09rem;
        }
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }
  .product-tab-content {
    width: 3.24rem;
    padding-top: .15rem;
    .content-item {
      width: 100%;
      display: none;
      &.active {
        display: block;
      }
      .item-img {
        width: 100%;
        height: 1.62rem;
      }
      .item-intro {
        font-size: .13rem;
        font-weight: 300;
        color: #FFFFFF;
        line-height: .24rem;
        padding-top: .12rem;
      }
    }
  }
  .mobile-partner {
    width: 100%;
    height: .88rem;
    position: absolute;
    bottom: 0;
    box-sizing: border-box;
    padding-left: .28rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    background-color: #FFFFFF;
    .partner-item {
      width: .60rem;
      height: .67rem;
      background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/partner-logo-bg.png);
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: .05rem;
      &:last-child {
        .item-img {
          width: .33rem;
        }
      }
      .item-img {
        width: .38rem;
      }
    }
  }
  .mobile-bottom-nav {
    width: 100%;
    background-color: #1E2021;
    .nav-list {
      width: 100%;
      .nav-item {
        width: 100%;
        height: .52rem;
        border-bottom: 1px solid rgba(255, 255, 255, .05);
        padding: 0 .46rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: .16rem;
        font-weight: 400;
        color: #BCBCBC;
        .nav-arrow {
          width: .07rem;
          height: .14rem;
        }
      }
    }
  }
  .mobile-footer {
    background-color: #000000;
    padding: .26rem 0;
    position: relative;
    .license-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: .11rem;
      font-weight: 300;
      color: #BCBCBC;
      .license-item {
        padding-bottom: .08rem;
        display: flex;
        align-items: center;
        .police-logo {
          width: .14rem;
          height: .14rem;
          padding-right: .06rem;
        }
        &:last-child {
          padding: 0;
        }
      }
    }
    .totop-icon {
      width: .35rem;
      height: .35rem;
      position: absolute;
      bottom: .20rem;
      right: .12rem;
    }
  }
  .menu-modal-super {
    .menu-list {
      width: 1.68rem;
      height: calc(100% - .57rem);
      position: absolute;
      top: .57rem;
      right: 0;
      background-color: rgba(0, 0, 0, .95);
      .menu-item {
        width: 100%;
        height: .60rem;
        border-bottom: 1px solid rgba(255, 255, 255, .1);
        padding: 0 .24rem;
        font-size: .16rem;
        font-weight: 300;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .arrow-icon {
          width: .07rem;
          height: .13rem;
        }
      }
    }
  }
  .video-modal-super {
    .video-player {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
