.intro-block {
  width: 100%;
  background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/mobile-section-bg.png);
  background-size: 100% auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  .block-super {
    width: 3.25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: .63rem;
    &:first-child {
      padding-top: .73rem;
    }
  }
  .block-title {
    width: 3.25rem;
    height: .34rem;
  }
  .intro-view {
    width: 3.25rem;
    padding-top: .08rem;
  }
  .text-super {
    padding-top: .10rem;
    font-size: .13rem;
    font-weight: 300;
    color: #FFFFFF;
    line-height: .30rem;
    text-shadow: 0 0 0.2em rgba(132, 255, 255, .45), -0 -0 0.2em rgba(132, 255, 255, .45);
  }
  .contact-content {
    width: 100%;
    padding-top: .19rem;
    padding-left: .13rem;
    .title {
      font-size: .11rem;
      font-weight: 500;
      color: #FFFFFF;
    }
    .info-list {
      padding-top: .13rem;
      padding-bottom: .20rem;
      .info-item {
        display: flex;
        font-size: .10rem;
        font-weight: 300;
        color: #FFFFFF;
        text-shadow: 0 0 0.2em rgba(132, 255, 255, .45), -0 -0 0.2em rgba(132, 255, 255, .45);
        padding-bottom: .10rem;
        .info-icon {
          width: .11rem;
          height: .11rem;
          padding-right: .13rem;
        }
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
    .qr-list {
      display: flex;
      padding-bottom: .40rem;
      .qr-item {
        font-size: .10rem;
        font-weight: 300;
        color: #FFFFFF;
        text-shadow: 0 0 0.2em rgba(132, 255, 255, .45), -0 -0 0.2em rgba(132, 255, 255, .45);
        text-align: center;
        padding-right: .30rem;
        .qr {
          width: .70rem;
        }
      }
    }
  }
}