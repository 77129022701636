/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-03-22 11:53:09
 */
.mobile-section {
  width: 100%;
  background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/mobile-section-bg.png);
  background-size: 100% auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: .73rem;
  padding-bottom: .33rem;
  .form-super {
    width: 3.25rem;
    background: #FAFAFA;
    box-shadow: 0 .03rem .03rem 0 rgba(0, 0, 0, 0.2);
    border-radius: .08rem;
    padding: .20rem .22rem .20rem;
    .title-super {
      padding-bottom: .11rem;
      text-align: center;
      .title {
        font-size: .16rem;
        font-weight: 500;
        color: #2A2A2A;
        padding-bottom: .06rem;
      }
      .subtitle {
        font-size: .09rem;
        font-weight: 300;
        color: #000000;
      }
    }
  }
  .form-content {
    .adm-input-element, .adm-text-area-element {
      --font-size: 14px;
      -webkit-box-shadow: 0 0 0 1000px #ffffff inset;
    }
    .picker-text {
      font-size: 14px;
      &.placeholder {
        color: #cccccc;
      }
    }
  }
}
