.video-center-block {
  width: 100%;
  background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/mobile-section-bg.png);
  background-size: 100% auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: .73rem;
  .block-title {
    width: 3.25rem;
    height: .34rem;
  }
  .tabs-super {
    width: 3.25rem;
    padding-top: .25rem;
    padding-bottom: .39rem;
  }
  .tab-title {
    // width: 3.24rem;
    box-sizing: border-box;
    border-bottom: 1px solid #6FA3CB;
    display: flex;
    font-size: .14rem;
    font-weight: 400;
    color: #FFFFFF;
    padding-left: .22rem;
    padding-bottom: .06rem;
    .title-item {
      margin-right: .30rem;
      opacity: 0.6;
      position: relative;
      &.active {
        opacity: 1;
        &::after {
          content: '';
          width: calc(100% + .12rem);
          height: .03rem;
          background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/product-active-bottom.png);
          background-size: 100% 100%;
          position: absolute;
          left: -.06rem;
          bottom: -.09rem;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .video-list {
    padding-top: .15rem;
    .video-item {
      width: 100%;
      background-color: rgba(0, 0, 0, .1);
      border: 1px solid rgba(255, 255, 255, .2);
      margin-bottom: .21rem;
      &:last-child {
        margin-bottom: 0;
      }
      .poster-super {
        position: relative;
        cursor: pointer;
        .image {
          width: 100%;
          height: 1.49rem;
        }
        .play-icon {
          width: .34rem;
          height: .40rem;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .text-super {
        padding: .09rem .16rem .13rem;
        font-weight: 400;
        color: #FFFFFF;
        .title {
          font-size: .12rem;
          padding-bottom: .10rem;
          border-bottom: 1px solid rgba(255, 255, 255, .2);
        }
        .subtitle {
          font-size: .10rem;
          padding-top: .10rem;
        }
        .date {
          font-size: .08rem;
          padding-top: .10rem;
          opacity: 0.5;
          text-align: right;
        }
      }
    }
  }
}