.nft {
  background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/nft-block-bg.png);
  .nft-block-title {
    width: 100%;
    height: .31rem;
    padding-top: .70rem;
  }
  .nft-content-super {
    padding: .21rem .16rem 0;
    color: #ffffff;
    text-shadow: 0 0 0.2em rgba(132, 255, 255, .45), -0 -0 0.2em rgba(132, 255, 255, .45);
    font-weight: 300;
    .nft-title {
      font-size: .15rem;
      padding-bottom: .15rem;
    }
    .nft-desc {
      font-size: .14rem;
      line-height: .20rem;
      padding-bottom: .26rem;
    }
    .mobile-nft-main-img {
      width: 100%;
    }
    .btn-super {
      padding-top: .26rem;
      text-align: center;
      position: relative;
      z-index: 100;
      .download-btn {
        width: 1.20rem;
        height: .35rem;
      }
    }
  }
}
.nft-tip-model {
  z-index: 10000;
  .share-arrow-super {
    padding: .06rem .44rem .12rem 0;
    text-align: right;
    .share-arrow {
      width: .54rem;
      height: .60rem;
    }
  }
  .share-mask-desc {
    font-size: .14rem;
    font-weight: 400;
    color: #FEFEFE;
    line-height: .20rem;
    padding-left: 1.79rem;
    text-align: left;
  }
}