.page-block {
  .tabs-super {
    .tab-menu-list {
      &.video-center {
        padding-right: 1rem;
      }
    }
    .video-content {
      width: 12.40rem;
      text-shadow: 0 0 0.2em rgba(132, 255, 255, .45), -0 -0 0.2em rgba(132, 255, 255, .45);
      .title-super {
        display: flex;
        justify-content: end;
        border-bottom: 1px solid rgba(255, 255, 255, .2);
        padding-bottom: .16rem;
        .en {
          font-size: .15rem;
          font-weight: 200;
          color: #FFFFFF;
          opacity: 0.5;
          padding-right: .27rem;
          display: flex;
          align-items: flex-end;
        }
        .name {
          font-size: .30rem;
          font-weight: 400;
          color: #FFFFFF;
        }
      }
      .video-list {
        display: flex;
        flex-wrap: wrap;
        padding-top: .37rem;
        .video-item {
          width: 3.64rem;
          background-color: rgba(0, 0, 0, .1);
          border: 1px solid rgba(255, 255, 255, .2);
          margin-bottom: .48rem;
          margin-right: .74rem;
          &:nth-child(3n) {
            margin-right: 0;
          }
          .poster-super {
            position: relative;
            cursor: pointer;
            .image {
              width: 100%;
              height: 1.89rem;
            }
            .play-icon {
              width: .60rem;
              height: .70rem;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
          .text-super {
            padding: .14rem .18rem;
            font-weight: 400;
            color: #FFFFFF;
            .title {
              font-size: .20rem;
              padding-bottom: .16rem;
              border-bottom: 1px solid rgba(255, 255, 255, .2);
            }
            .subtitle {
              font-size: .17rem;
              padding-top: .17rem;
              word-break: break-all;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2; /* 这里是超出几行省略 */
              overflow: hidden;
              height: .50rem;
              box-sizing: content-box;
            }
            .date {
              font-size: .13rem;
              padding-top: .15rem;
              text-align: right;
              opacity: 0.5;
            }
          }
        }
      }
    }
  }
}
