.pc-nft-container {
  background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/pc-nft-block-bg.png);
  .nft-content-super {
    padding: .70rem 2.30rem 0 2.80rem;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    .nft-info-super {
      width: 7.98rem;
      .nft-title {
        font-size: .28rem;
        font-weight: 300;
        padding-bottom: .58rem;
        text-shadow: 0 0 0.2em rgba(132, 255, 255, .45), -0 -0 0.2em rgba(132, 255, 255, .45);
      }
      .nft-desc {
        font-size: .22rem;
        font-weight: 300;
        padding-bottom: .75rem;
        text-shadow: 0 0 0.2em rgba(132, 255, 255, .45), -0 -0 0.2em rgba(132, 255, 255, .45);
      }
      .nft-qr-list {
        display: flex;
        .nft-qr-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding-right: .80rem;
          .qr-item-img {
            width: 1.80rem;
            height: 1.80rem;
          }
          .qr-item-text {
            font-size: .16rem;
            padding-top: .15rem;
          }
        }
      }
    }
    .pc-nft-main-img {
      width: 5rem;
    }
  }
}