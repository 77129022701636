.pc-container {
  width: 100%;
  min-width: 1280px;
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
  .loading-super {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2000;
    background-color: #000000;
    .video-loading {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .float-super {
    width: 100%;
    min-width: 1280px;
    max-width: 1920px;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 200;
    .mouse-icon-super {
      position: absolute;
      width: .24rem;
      height: .9rem;
      right: .61rem;
      bottom: .56rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: hidden;
      .mouse-icon {
        width: .24rem;
        height: .38rem;
        margin-bottom: .16rem;
      }
      .mouse-arrow-icon {
        width: .12rem;
        height: .15rem;
        animation: slide 1.2s linear infinite;
      }
    }
    .receive-info-list {
      width: .40rem;
      position: absolute;
      right: .53rem;
      bottom: 1.84rem;
      cursor: pointer;
      border-radius: .05rem;
      background-color: #FFFFFF;
      box-shadow: 0 .13rem .21rem 0 rgba(0, 5, 30, 0.05);
      .info-item {
        width: .40rem;
        height: .40rem;
        border-bottom: 2px solid rgba(0, 5, 30, 0.05);
        background-position: center center;
        background-repeat: no-repeat;
        position: relative;
        &:last-child {
          border-bottom: none;
        }
        &.top {
          background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/receive-to-top.png);
          background-size: .22rem .22rem;
        }
        &.mouse {
          background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/receive-mouse.gif);
          background-size: .17rem .26rem;
        }
        &.chatgpt {
          background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/receive-chatgpt.png);
          background-size: .41rem .41rem;
          &:hover {
            background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/receive-chatgpt-hover.png);
            .info-content {
              display: block;
            }
          }
          .info-content {
            position: absolute;
            background: #FFFFFF;
            box-shadow: 0 .13rem .21rem 0 rgba(0, 5, 30, 0.05);
            border-radius: .02rem;
            padding: .08rem .15rem;
            top: .02rem;
            right: .44rem;
            display: none;
            font-size: .12rem;
            font-weight: 600;
            color: #454545;
          }
        }
        &.info {
          background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/receive-info.png);
          background-size: .25rem .23rem;
          &:hover {
            background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/receive-info-hover.png);
            .info-content {
              display: block;
            }
          }
          .info-content {
            position: absolute;
            width: 1.47rem;
            background: #FFFFFF;
            box-shadow: 0 .13rem .21rem 0 rgba(0, 5, 30, 0.05);
            border-radius: .02rem;
            padding: .14rem .15rem;
            top: -.12rem;
            right: .44rem;
            display: none;
            .title {
              font-size: .13rem;
              font-weight: 300;
              color: #1664FF;
              padding-bottom: .03rem;
            }
            .subtitle {
              font-size: .12rem;
              font-weight: 300;
              color: #454545;
            }
          }
        }
        &.gzh {
          background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/gzh-info.png);
          background-size: .23rem .22rem;
          &:hover {
            background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/gzh-info-hover.png);
            .gzh-content {
              display: block;
            }
          }
          .gzh-content {
            position: absolute;
            width: 1.24rem;
            background: #FFFFFF;
            box-shadow: 0 .13rem .21rem 0 rgba(0, 5, 30, 0.05);
            border-radius: .02rem;
            padding-top: .14rem;
            padding-bottom: .08rem;
            top: -.61rem;
            right: .47rem;
            font-size: .12rem;
            font-weight: 300;
            color: #454545;
            text-align: center;
            display: none;
            .qr-img {
              width: .97rem;
              height: .97rem;
              padding-top: .05rem;
            }
          }
        }
        &.consultant {
          background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/consultant-info.png);
          background-size: .25rem .20rem;
          &:hover {
            background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/consultant-info-hover.png);
            .consultant-content {
              display: block;
            }
          }
          .consultant-content {
            position: absolute;
            width: 1.24rem;
            background: #FFFFFF;
            box-shadow: 0 .13rem .21rem 0 rgba(0, 5, 30, 0.05);
            border-radius: .02rem;
            padding-top: .14rem;
            padding-bottom: .08rem;
            top: -.61rem;
            right: .47rem;
            text-align: center;
            display: none;
            .title {
              font-size: .12rem;
              font-weight: 300;
              color: #1664FF;
              padding-bottom: .03rem;
            }
            .subtitle {
              font-size: .11rem;
              font-weight: 300;
              color: #454545;
            }
            .qr-img {
              width: .85rem;
              height: .85rem;
              padding-top: .09rem;
            }
          }
        }
      }
    }
  }
  .header-mask {
    height: .90rem;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    // background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 0%, rgba(0, 0, 0, 0.1) 40%, rgba(0, 0, 0, 0.2) 98%, rgba(0, 0, 0, 0) 100%);
    background: rgba(0, 0, 0, 0.2);
  }
  .header-super {
    height: .90rem;
    width: 100%;
    min-width: 1280px;
    max-width: 1920px;
    padding: 0 .44rem;
    display: flex;
    position: fixed;
    box-sizing: border-box;
    z-index: 100;
    justify-content: space-between;
    align-items: center;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    .header-logo {
      width: 1.65rem;
      height: .51rem;
      cursor: pointer;
    }
    .header-content {
      display: flex;
      align-items: center;
      .menu-list {
        display: flex;
        justify-content: space-between;
        height: .49rem;
        line-height: .49rem;
        padding-right: .50rem;
        .menu-item {
          font-size: .14rem;
          color: #FFFFFF;
          position: relative;
          margin-right: 20px;
          cursor: pointer;
          &:last-child {
            margin-right: 0;
          }
          &.active {
            text-shadow: 0 0 0.2em rgba(132, 255, 255, .45), -0 -0 0.2em rgba(132, 255, 255, .45);
          }
          &::before {
            content: '';
            width: 0%;
            height: .02rem;
            background-color: #69E0FF;
            position: absolute;
            left: 0;
            top: 0;
            transition: width .3s;
          }
          &:hover::before {
            width: 100%;
          }
          &.active::before {
            width: 100%;
          }
        }
      }
      .explore {
        font-size: .14rem;
        color: #FFFFFF;
        width: 1.24rem;
        height: .34rem;
        line-height: .34rem;
        text-align: center;
        cursor: pointer;
        border-radius: .17rem;
        border: 1px solid #FFFFFF;
      }
    }
  }
  .page-index {
    width: 100%;
    height: 100%;
    position: relative;
    background-color: #000000;
    .section-bg {
      position: absolute;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .index-cover {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/index-cover.png);
      z-index: 2;
    }
    .audio-play-super {
      position: absolute;
      width: .31rem;
      height: .31rem;
      top: 1.20rem;
      right: .55rem;
      z-index: 3;
      cursor: pointer;
      .audio-play-icon {
        width: .31rem;
        height: .31rem;
      }
      .audio-pause-icon {
        width: .11rem;
        height: .11rem;
        position: absolute;
        bottom: 0;
        right: 0;
        display: none;
        &.show {
          display: block;
        }
      }
    }
    .control-super {
      position: absolute;
      bottom: 1.01rem;
      left: 50%;
      transform: translateX(-50%);
      height: 1.51rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 3;
      .arrow-icon {
        width: .36rem;
        height: .52rem;
        cursor: pointer;
        &.right-arrow {
          transform: rotateY(180deg)
        }
      }
      .button-super {
        position: relative;
        .button-bg {
          height: 1px;
          width: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        .button-list {
          position: relative;
          display: flex;
          align-items: center;
          .button-item {
            width: .96rem;
            height: 1.11rem;
            margin-right: .40rem;
            position: relative;
            cursor: pointer;
            position: relative;
            &.active {
              width: 1.10rem;
              height: 1.28rem;
              cursor: default;
              .play-icon {
                display: none;
              }
              .video-play {
                display: block;
              }
            }
            .button-img {
              width: 100%;
              height: 100%;
            }
            .video-play {
              display: none;
              width: .96rem;
              height: 1.16rem;
              position: absolute;
              top: .06rem;
              left: .07rem;
            }
            .play-icon {
              width: .44rem;
              height: .49rem;
              position: absolute;
              top: .36rem;
              left: .32rem;
            }
          }
          .button-item:first-child {
            margin-left: 2.07rem;
          }
          .button-item:last-child {
            margin-right: 2.07rem;
          }
        }
      }
      
    }
    .dots-super {
      position: absolute;
      bottom: .60rem;
      left: 50%;
      transform: translateX(-50%);
      height: .14rem;
      z-index: 3;
      .dots-list {
        display: flex;
        align-items: center;
        .dots-item {
          height: .14rem;
          width: .14rem;
          border-radius: .07rem;
          background-color: #566372;
          margin-right: .11rem;
          overflow: hidden;
          &.active {
            width: .59rem;
            height: .12rem;
            border-radius: .06rem;
            .play-progress {
              height: .12rem;
              display: block;
              background-color: #FFFFFF;
              border-radius: .06rem;
            }
          }
          .play-progress {
            display: none;
          }
        }
        .dots-item:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .section-block {
    width: 100%;
    height: 100%;
    position: relative;
    background-color: #000000;
    background-size: cover;
    overflow: hidden;
    .product-content {
      position: relative;
      padding-top: 1.17rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 10;
      .product-title {
        width: 14.19rem;
        height: .80rem;
      }
      .assets-subtitle {
        font-size: .22rem;
        color: #FFFFFF;
        text-shadow: 0 0 0.2em rgba(132, 255, 255, .45), -0 -0 0.2em rgba(132, 255, 255, .45);
        padding-top: .25rem;
        margin-left: -9rem;
        font-weight: 300;
      }
      .infinite-subtitle {
        font-size: .22rem;
        color: #FFFFFF;
        text-shadow: 0 0 0.2em rgba(132, 255, 255, .45), -0 -0 0.2em rgba(132, 255, 255, .45);
        padding-top: .4rem;
        width: 12rem;
        font-weight: 300;
      }
      .accordion {
        position: relative;
        width: 13.44rem;
        height: 5.34rem;
        margin-top: .47rem;
        .unchecked {
          position: absolute;
          top: 0;
          width: 3.36rem;
          height: 5.34rem;
          cursor: pointer;
          background-repeat: no-repeat;
          background-size: auto 100%;
          background-position: center top;
          -webkit-transition: all .5s;
          -moz-transition: all .5s;
          -ms-transition: all .5s;
          transition: all .5s;
          &.on {
            width: 5.58rem;
            z-index: 4;
            cursor: default;
          }
          &:nth-of-type(1) {
            left: 0;
            background-position: left top;
            overflow: hidden;
            .zg1 {
              position: absolute;
              bottom: 0;
              left: 0;
              content: "";
              display: none;
              width: 100%;
              height: 5.09rem;
              z-index: 5;
              background-color: rgba(0,0,0,.8);
            }
            .zg2 {
              position: absolute;
              top: -0.29rem;
              left: 0;
              content: "";
              display: none;
              width: 100%;
              height: .01rem;
              z-index: 5;
              border: .27rem solid transparent;
              border-bottom-color: rgba(0,0,0,.8);
              border-right-color: rgba(0,0,0,.8);
            }
          }
          &:nth-of-type(2) {
            left: 3.36rem;
            &.on {
              left: 2.25rem;
            }
            .zg1 {
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              z-index: 5;
              content: "";
              display: none;
              background-color: rgba(0,0,0,.8);
            }
          }
          &:nth-of-type(3) {
            left: 6.72rem;
            &.on {
              left: 5.61rem;
            }
            .zg1 {
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              z-index: 5;
              content: "";
              display: none;
              background-color: rgba(0,0,0,.8);
            }
          }
          &:nth-of-type(4) {
            right: 0;
            background-position: right top;
            overflow: hidden;
            .zg1 {
              position: absolute;
              top: 0;
              left: 0;
              content: "";
              display: none;
              width: 100%;
              height: 5.16rem;
              z-index: 5;
              background-color: rgba(0,0,0,.8);
            }
            .zg2 {
              position: absolute;
              bottom: -.20rem;
              right: 0;
              content: "";
              display: none;
              width: 100%;
              height: .01rem;
              z-index: 5;
              border: .19rem solid transparent;
              border-top-color: rgba(0,0,0,.8);
              border-left-color: rgba(0,0,0,.8);
            }
          }
        }
      }
      .product-list {
        display: flex;
        padding-top: 1.14rem;
        .product-item {
          position: relative;
          width: 4.17rem;
          box-sizing: border-box;
          margin-right: .76rem;
          &::before {
            content: '';
            position: absolute;
            width: 3.93rem;
            height: 2.52rem;
            top: -.19rem;
            left: -.23rem;
            opacity: 0;
            background-color: #64d4f4;
            z-index: 2;
            transition: all .7s cubic-bezier(0,1,.5,1) 0s;
          }
          &::after {
            content: '';
            position: absolute;
            width: 3.93rem;
            height: 2.52rem;
            background-image: url('https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/product-animation-bg.png');
            background-size: cover;
            top: -.19rem;
            left: -.23rem;
            z-index: 1;
            transition: all .7s cubic-bezier(0,1,.5,1) 0s,opacity .2s ease;
          }
          &:hover {
            &::before {
              opacity: 1;
              left: .41rem;
            }
            &::after {
              left: .41rem;
            }
            .item-icon {
              left: 0rem;
              background-color: #182e4a;
            }
            .item-content {
              margin-left: 0;
              background-color: rgba(0, 0, 0, .2);
            }
          }
          .product-box {
            width: 4.54rem;
            height: 5.74rem;
            padding-top: 0.37rem;
            padding-left: 0.37rem;
            margin-top: -0.37rem;
            margin-left: -0.37rem;
          }
          .item-img {
            width: 4.17rem;
            height: 2.52rem;
            position: relative;
            z-index: 4;
          }
          .item-icon {
            width: .71rem;
            height: .28rem;
            position: absolute;
            top: -.37rem;
            left: -.37rem;
            z-index: 3;
            background-color: #284d7c;
            text-align: center;
            font-size: .13rem;
            line-height: .28rem;
            color: #FFFFFF;
            transition: all .7s cubic-bezier(0,1,.5,1) 0s,background-color .2s ease;
          }
          .item-content {
            position: absolute;
            width: 4.17rem;
            height: 3.04rem;
            top: 2.33rem;
            left: 0;
            padding-left: .27rem;
            padding-right: .44rem;
            margin-left: -.27rem;
            box-sizing: border-box;
            transition: margin-left .5s cubic-bezier(0,1,.5,1) 0s,background-color .5s cubic-bezier(0,1,.5,1) 0s;
            .item-title {
              font-size: .22rem;
              font-weight: 300;
              color: #FFFFFF;
              padding-top: .43rem;
              padding-bottom: .06rem;
              text-shadow: 0 0 0.2em rgba(132, 255, 255, .45), -0 -0 0.2em rgba(132, 255, 255, .45);
            }
            .item-en {
              font-size: .13rem;
              font-family: DengXian;
              color: #FFFFFF;
              opacity: 0.4;
              padding-bottom: .30rem;
            }
            .item-intro {
              font-size: .15rem;
              font-weight: 300;
              color: #FFFFFF;
              line-height: .28rem;
              text-shadow: 0 0 0.2em rgba(132, 255, 255, .45), -0 -0 0.2em rgba(132, 255, 255, .45);
            }
          }
        }
        .product-item:last-child {
          margin-right: 0;
        }
      }
      .mobile-super {
        padding-top: .72rem;
        text-align: center;
        font-size: .22rem;
        font-weight: 400;
        color: #FFFFFF;
        font-weight: 300;
        text-shadow: 0 0 0.2em rgba(132, 255, 255, .45), -0 -0 0.2em rgba(132, 255, 255, .45);
        .intro {
          padding-bottom: .10rem;
        }
        .name {
          margin-top: -.35rem
        }
        .mobile-img {
          width: 8.10rem;
          height: 4.77rem;
        }
      }
      .pc-vr-super {
        display: flex;
        font-size: .22rem;
        font-weight: 400;
        color: #FFFFFF;
        font-weight: 300;
        text-shadow: 0 0 0.2em rgba(132, 255, 255, .45), -0 -0 0.2em rgba(132, 255, 255, .45);
        margin-top: -2.4rem;
        .pc-type {
          width: 3.99rem;
          margin-right: 6.01rem;
          .intro {
            font-size: .15rem;
            padding-bottom: .36rem;
          }
          .name {
            margin-top: -.7rem;
          }
          .pc-img {
            width: 3.99rem;
            height: 3.45rem;
          }
        }
        .vr-type {
          width: 4.71rem;
          .intro {
            font-size: .15rem;
            padding: 0 .80rem .3rem 1.45rem;
          }
          .name {
            margin-top: -.6rem;
            padding-left: 3.2rem;
          }
          .vr-img {
            width: 4.71rem;
            height: 3.41rem;
          }
        }
      }
      .digital-link-super {
        display: flex;
        width: 13.87rem;
        padding-top: 0.37rem;
        .title {
          flex: 1 1 auto;
          font-size: 0.22rem;
          color: #FFFFFF;
          text-shadow: 0 0 0.2em rgb(132 255 255 / 45%), 0 0 0.2em rgb(132 255 255 / 45%);
          font-weight: 300;
        }
        .digital-link-button {
          width: 1.85rem;
          height: .42rem;
          line-height: .42rem;
          border: 1px solid rgba(255, 255, 255, .4);
          text-align: center;
          font-size: .19rem;
          font-weight: 400;
          color: #FEFEFE;
          cursor: pointer;
        }
      }
      .digital-person-list {
        width: 13.87rem;
        display: flex;
        justify-content: space-between;
        padding-top: .52rem;
        background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/digital-icon-line.png);
        background-size: contain;
        background-position: 0 3.81rem;
        background-repeat: no-repeat;
        .digital-person-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-weight: 400;
          color: #FFFFFF;
          text-shadow: 0 0 0.2em rgba(132, 255, 255, .45), -0 -0 0.2em rgba(132, 255, 255, .45);
          .item-person {
            width: 3.23rem;
            height: 2.79rem;
          }
          .item-icon {
            width: .51rem;
            height: .51rem;
            padding-top: .30rem;
          }
          .item-step {
            width: .75rem;
            height: .26rem;
            padding-top: .15rem;
          }
          .item-en {
            font-size: .22rem;
            font-family: Lato;
            font-weight: 100;
            padding-top: .25rem
          }
          .item-name {
            font-size: .24rem;
            padding-top: .10rem;
          }
        }
      }
      .partner-logos {
        width: 17.06rem;
        position: absolute; 
        top: 2.72rem;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .contact-super {
      display: flex;
      width: 12rem;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      .company-img {
        width: 4.42rem;
        height: 3.45rem;
        padding-right: 1rem;
      }
      .info-list {
        padding: .30rem 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .info-item {
          display: flex;
          align-items: center;
          font-size: .20rem;
          font-weight: 300;
          color: #FFFFFF;
          text-shadow: 0 0 0.2em rgba(132, 255, 255, .45), -0 -0 0.2em rgba(132, 255, 255, .45);
          .info-icon {
            width: .25rem;
            height: .25rem;
            padding-right: .13rem;
          }
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
    }
    .animate-super {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      user-select: none;
      .assets-car {
        width: 6.27rem;
        height: 3.73rem;
        position: absolute;
        bottom: .8rem;
        right: 3.8rem;
      }
      .assets-woman {
        width: 2.48rem;
        height: 6.61rem;
        position: absolute;
        bottom: .3rem;
        left: 8.46rem;
      }
      .assets-box-1 {
        width: 5.79rem;
        height: 2.85rem;
        position: absolute;
        bottom: 1.70rem;
        left: 2.24rem;
        visibility: hidden;
        transition: visibility 0s .5s;
        &.twinkle {
          animation: twinkling 1s ease-in-out .5s;
          visibility: visible;
        }
      }
      .assets-box-2 {
        width: 2.67rem;
        height: 1.54rem;
        position: absolute;
        bottom: .75rem;
        left: 8.26rem;
        visibility: hidden;
        transition: visibility 0s 1s;
        &.twinkle {
          animation: twinkling 1s ease-in-out 1s;
          visibility: visible;
        }
      }
      .assets-box-3 {
        width: 3.06rem;
        height: 3.11rem;
        position: absolute;
        bottom: 1.93rem;
        right: 4.81rem;
        visibility: hidden;
        transition: visibility 0s 1.5s;
        &.twinkle {
          animation: twinkling 1s ease-in-out 1.5s;
          visibility: visible;
        }
      }
      .assets-img-1 {
        width: 2.04rem;
        height: 1.82rem;
        position: absolute;
        bottom: 3.29rem;
        left: 2.64rem;
        visibility: hidden;
        transition: visibility 0s 0s;
        &.appear {
          animation: appearLeft .5s linear;
          visibility: visible;
        }
      }
      .assets-img-2 {
        width: 1.89rem;
        height: 1.51rem;
        position: absolute;
        bottom: 1.35rem;
        left: 6.33rem;
        visibility: hidden;
        transition: visibility 0s .5s;
        &.appear {
          animation: appearLeft .5s linear .5s;
          visibility: visible;
        }
      }
      .assets-img-3 {
        width: 2.37rem;
        height: 1.81rem;
        position: absolute;
        bottom: 4.27rem;
        right: 2.45rem;
        visibility: hidden;
        transition: visibility 0s 1s;
        &.appear {
          animation: appearRight .5s linear 1s;
          visibility: visible;
        }
      }
    }
    .back-arrow {
      width: .87rem;
      height: .45rem;
      position: absolute;
      left: 50%;
      bottom: 1.69rem;
      transform: translateX(-50%);
      cursor: pointer;
      margin-bottom: -.05rem;
      transition: margin .3s;
      z-index: 20;
    }
    .back-arrow:hover {
      margin-bottom: 0;
    }
  }
  .footer-super {
    width: 100%;
    height: 1.71rem;
    background-color: #1E2021;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    &.fullpage {
      position: absolute;
      bottom: 0;
      z-index: 21;
    }
    .logo-content {
      display: flex;
      margin-right: 1rem;
      .logo-td {
        width: 1.59rem;
        height: .41rem;
        margin-right: .27rem;
        cursor: pointer;
      }
      .logo-qy {
        width: 1.63rem;
        height: .49rem;
        cursor: pointer;
      }
      .logo-devide {
        width: .02rem;
        height: .41rem;
        margin-right: .35rem;
        background-color: #FFFFFF;
      }
    }
    .message-content {
      .menu-list {
        display: flex;
        font-size: .15rem;
        font-weight: 300;
        color: #BCBCBC;
        line-height: .15rem;
        padding-bottom: .15rem;
        .menu-item {
          padding-right: .16rem;
          border-right: 1px solid #BCBCBC;
          margin-right: .19rem;
          cursor: pointer;
        }
        .menu-item:last-child {
          border: none;
        }
      }
      .license-list {
        display: flex;
        font-size: .15rem;
        font-weight: 300;
        color: #BCBCBC;
        line-height: .15rem;
        .license-item {
          padding-right: .16rem;
          border-right: 1px solid #BCBCBC;
          margin-right: .16rem;
          display: flex;
          align-items: center;
          cursor: pointer;
          .police-logo {
            width: .20rem;
            height: .20rem;
            padding-right: .10rem;
          }
          &:last-child {
            border: none;
            padding: 0;
            margin: 0;
          }
        }
      }
      .conpany-name {
        font-size: .15rem;
        font-weight: 300;
        color: #BCBCBC;
      }
    }
  }
  .collection-modal-super {
    .modal-content {
      width: 9.61rem;
      height: 7.24rem;
      background-color: #FFFFFF;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 2px;
      .close-icon {
        width: .33rem;
        height: .33rem;
        position: absolute;
        top: .20rem;
        right: .20rem;
        cursor: pointer;
      }
      .title {
        font-size: .31rem;
        font-weight: bold;
        color: #2A2A2A;
        text-align: center;
        padding-top: .54rem;
      }
      .subtitle {
        font-size: .17rem;
        font-weight: 300;
        color: #000000;
        line-height: .25rem;
        text-align: center;
        padding-top: .12rem;
      }
      .form-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .form {
          width: 8rem;
          padding-top: .64rem;
          .adm-list-body, .adm-input-element, .adm-text-area-element {
            --font-size: .20rem;
          }
          .adm-input-element {
            width: 2.24rem;
          }
          .adm-list {
            --border-top: 0;
            --border-bottom: 0;
            --border-inner: 0;
          }
          .form-row {
            display: flex;
            justify-content: space-between;
            height: 75px;
          }
          .adm-form-footer {
            text-align: center;
            .adm-button {
              width: 3.12rem;
            }
          }
          .adm-text-area {
            padding: .05rem;
            border: 1px solid #e6e8eb;
          }
          .adm-input {
            padding: .05rem;
            border: 1px solid #e6e8eb;
            input {
              -webkit-box-shadow: 0 0 0 1000px #ffffff inset;
            }
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
            }
          }
          .adm-list-item-content-prefix {
            padding-top: 18px;
            padding-right: 0;
          }
          .form-select {
            appearance: auto;
            &:focus {
              outline: none;
            }
          }
        }
      }
      .complete-content {
        .complete-circle {
          position: absolute;
          top: 1.58rem;
          left: 4.25rem;
          width: 1.00rem;
          height: 1.01rem;
        }
        .complete-icon {
          position: absolute;
          top: 1.90rem;
          left: 4.52rem;
          width: .48rem;
          height: .37rem;
        }
        .complete-title {
          font-size: .31rem;
          font-weight: bold;
          color: #2A2A2A;
          text-align: center;
          padding-top: 2.91rem;
        }
        .complete-subtitle {
          font-size: .18rem;
          font-weight: 300;
          color: #000000;
          line-height: .25rem;
          text-align: center;
          padding-top: .21rem;
        }
        .button-super {
          display: flex;
          justify-content: center;
          padding-top: .42rem;
          .button-close {
            width: 1.28rem;
            height: .35rem;
            border-radius: .10rem;
            line-height: .35rem;
            text-align: center;
            font-size: .22rem;
            font-weight: 400;
            color: #FFFFFF;
            background-color: #539FFE;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@keyframes heart{
  from{transform: translate(-50%, -10px)}
  to{transform: translate(-50%, 10px)}
}

@keyframes slide{
  from{margin-top: 0;}
  to{margin-top: .8rem;}
}

@keyframes twinkling{	/*透明度由0到1*/
  0% {
    opacity:0;
  }
  20% {
    opacity:.8;
  }
  40% {
    opacity:.2;
  }
  60% {
    opacity:.8;
  }
  80% {
    opacity:.2;
  }
	100%{
		opacity:1;				/*透明度为1*/
	}
}

@keyframes appearLeft{
  from{margin-left: -1rem; opacity: 0;}
  to{margin-left: 0; opacity: 1;}
}

@keyframes appearRight{
  from{margin-right: -1rem; opacity: 0;}
  to{margin-right: 0; opacity: 1;}
}

@keyframes appearRight2{
  from{margin-left: 1rem; opacity: 0;}
  to{margin-left: 0; opacity: 1;}
}
