.page-block {
  width: 100%;
  min-height: 10.80rem;
  position: relative;
  background-color: #000000;
  background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/product-container.png);
  background-size: cover;
  overflow: hidden;
  .tabs-super {
    display: flex;
    padding-left: 1.52rem;
    padding-top: 1.57rem;
    position: relative;
    z-index: 10;
    .tab-menu-list {
      display: flex;
      flex-direction: column;
      padding-right: .33rem;
      padding-top: .95rem;
      .tab-menu-item {
        font-size: .22rem;
        color: #FFFFFF;
        position: relative;
        cursor: pointer;
        padding: .30rem .21rem;
        box-sizing: content-box;
        border-left: 1px solid rgba(255, 255, 255, .3);
        &.active {
          text-shadow: 0 0 0.2em rgba(132, 255, 255, .45), -0 -0 0.2em rgba(132, 255, 255, .45);
          text-decoration: underline;
          &::before {
            background-color: #FFFFFF;
          }
          .menu-arrow {
            display: block;
          }
        }
        &::before {
          content: '';
          position: absolute;
          width: .04rem;
          height: .04rem;
          border-radius: .02rem;
          background-color: rgba(255, 255, 255, .3);
          left: -.22rem;
          top: .45rem;
        }
        .menu-arrow {
          position: absolute;
          width: .10rem;
          height: .20rem;
          left: 1.52rem;
          top: .39rem;
          display: none;
        }
      }
    }
    .tab-content {
      width: 14.18rem;
      height: 7.85rem;
      background-size: contain;
      background-repeat: no-repeat;
      text-shadow: 0 0 0.2em rgba(132, 255, 255, .45), -0 -0 0.2em rgba(132, 255, 255, .45);
      color: #FFFFFF;
      position: relative;
      &.company {
        display: flex;
        padding: 1.15rem .53rem 0 .87rem;
        background-image: url('https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/intro-bg-1.png');
        .left-super {
          margin-right: .70rem;
          .img-super {
            width: 5.52rem;
            height: 4.31rem;
            position: relative;
            .bg-icon {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: .95rem;
              height: 1.04rem;
              cursor: pointer;
            }
            .company-img {
              width: 5.52rem;
              height: 4.31rem;
            }
          }
          .dots-list {
            display: flex;
            padding-top: .10rem;
            .dots-item {
              width: .60rem;
              height: .06rem;
              background-color: rgba(255, 255, 255, .3);
              margin-right: .13rem;
              &.active {
                background-color: rgba(255, 255, 255, .8);
              }
            }
          }
        }
        .text-super {
          font-size: .20rem;
          font-weight: 300;
          line-height: .28rem;
          padding-top: .27rem;
          .text-block {
            padding-bottom: .36rem;
          }
        }
      }
      &.contact {
        background-image: url('https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/qiyuan/contact-content-bg.png');
        .address {
          font-size: .40rem;
          padding-top: .55rem;
          padding-left: 2.49rem;
        }
        .en-address {
          font-size: .20rem;
          font-weight: 300;
          padding-left: 2.55rem;
        }
        .name {
          font-size: .25rem;
          padding-top: 3.64rem;
          padding-left: 1.87rem;
        }
        .info {
          font-size: .20rem;
          font-weight: 300;
          line-height: .28rem;
          padding-top: .15rem;
          padding-left: 1.87rem;
          div {
            padding-bottom: .08rem;
          }
        }
      }
    }
  }
}
.video-modal-super {
  .modal-content {
    width: 60%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .close-icon {
      width: .33rem;
      height: .33rem;
      position: absolute;
      top: 0;
      right: -.53rem;
      cursor: pointer;
    }
    .video-player {
      width: 100%;
    }
  }
}
