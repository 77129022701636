@primary-color: white;
@bg: black;

html, body {
  padding: 0;
  margin: 0;
  font-family: SourceHanSansCN;
  background-color: #000000;
}

ul, li { 
  padding: 0;
  margin: 0;
  list-style: none;
}

div, ul, li {
  box-sizing: border-box;
}

// h1 {
//   color: @primary-color;
//   background-color: @bg;
// }